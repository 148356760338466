<template>
  <div
    v-if="data?.paymentIcons?.[0]"
    class="payment-icons"
  >
    <div
      v-if="data?.paymentIcons[0]?.Title"
      class="payment-icons__title"
    >
      {{ data?.paymentIcons[0].Title }}
    </div>

    <ul class="payment-icons__list">
      <li
        v-for="group in data.paymentIcons[0].Icons"
        :key="group"
      >
        <component
          :is="getElement(group.Link)"
          class="payment-icons__link"
          :class="`${group.Icon}`"
          :to="group.Link"
        >
          <component
            :is="loadIcon(group.Icon)"
            v-if="group.Icon"
            filled
            class="icon"
            :class="`icon--${useDash(group.Icon)}`"
          />
          <span class="visually-hidden">{{ group.Name }}</span>
        </component>
      </li>
    </ul>
  </div>
</template>

<script lang="ts" setup>
import layoutQuery from '~layers/app/graphql/layout.gql'

import {
  AppLink,
} from '#components'

const { public: config } = useRuntimeConfig()
const { data } = await useAsyncQuery(layoutQuery, { locale: config.strapiLocale })

function getElement(to: any) {
  return to ? AppLink : 'span'
}

const { loadIcon } = useStrapiIcons()
</script>

<!-- <style src="~layers/app/components/App/PaymentIcons.css" /> -->
<style>
@import url("~layers/app/components/App/PaymentIcons.css");
</style>
